import request from '@/core/services/api.services.request'

export function getAllEmployees(params) {
    return request({
      url: '/user/list',
      method: 'get',
      params: params
    })
  }

  export function getEmployeeSheet(params) {
    return request({
      url: '/employee/time/sheet',
      method: 'get',
      params: params
    })
  }

  export function getEmployeeList() {
    return request({
      url: '/employee/list',
      method: 'get',
    })
  }

  export function getEmployeeDetails(params) {
    return request({
      url: '/employee/time/details',
      method: 'get',
      params: params
    })
  }

  export function downloadPdf(params) {
    return request({
      url: '/pdf/download/employeeTimeSheet',
      method: 'get',
      responseType: 'blob',
      params: params
    })
  }

  export function getEmployeeAllDetails(params) {
    return request({
      url: '/employee/alltime/details',
      method: 'get',
      params: params
    })
  }

  export function downloadAllEmployeePdf(params) {
    return request({
      url: '/employee/timesheet/all',
      method: 'get',
      responseType: 'blob',
      params: params
    })
  }

  export function downloadAllEmployeeExcel(params) {
    return request({
      url: '/export/timesheet/excel',
      method: 'get',
      responseType: 'blob',
      params: params
    })
  }

  export function downloadEmployeeExcel(params) {
    return request({
      url: '/employee/timesheet/excel',
      method: 'get',
      responseType: 'blob',
      params: params
    })
  }

  export function getTime() {
    
    return request({
      url: '/server/time',
      method: 'get',
    })
  }
  
